import React, { useState, useEffect } from 'react';
import * as qs from 'query-string';
import { connect } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
/* */
import master from '_utils/master';
import helpers from '_utils/helpers';
import * as Paybox from '_redux/paybox';
import * as Customer from '_redux/customer';
import { Screen } from '_components/core';
import { CreditCardPreview } from '_components/elements';
import RecurringPayment from '_components/RecurringPayment';

/* */
const ConfirmRecurPayment = ({
  golf,
  history,
  location,
  match: { params },
}) => {
  /* */
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  /* */
  const [customer, setCustomer] = useState(null);
  const [match, setMatch] = React.useState(null);
  const [session3DS, setSession3DS] = useState(null);
  const [imprint, setImprint] = useState(null);
  const [selected, setSelected] = useState(null);

  const queryParams = qs.parse(location.search);
  const { id = null } = params;
  const { amount: queryAmount = 1000 } = queryParams;
  const amount = parseInt(queryAmount, 10);

  /* */
  const load = () => {
    Customer.api.get(master, { id })
      .then((cust) => {
        const m = Customer.functions.getMatch(cust, golf);
        if (m) {
          setMatch(m);
        }
        setCustomer(cust);

        // build session 3DS
        setSession3DS({
          address: {
            city: golf.city,
            countryCode: 250,
            line1: golf.name,
            zipCode: golf.postalCode,
          },
          customer: {
            email: cust.email,
            lastName: cust.lastname,
            firstName: cust.firstname,
          },
        });

        return Paybox.api.getCreditCards(master, { accountId: id })
          .then(([cc]) => {
            if (cc) {
              const status = Paybox.functions.getStatus(cc.dueDate);
              setImprint({
                ...cc,
                ...status,
              });
            }
          });
      })
      .catch((err) => setError(err.message))
      .finally(() => setLoading(false));
  };

  useEffect(load, []);

  /* */
  const payWithCardImprint = () => {
    setSelected(imprint);
    history.replace('recurring-payment?status=successful');
  };

  /* */
  const payWithNewCard = (values, { setSubmitting }) => {
    setError(null);

    const { id3D = null } = values;

    const uuid = Paybox.functions.createUuidFrom(customer.id);

    const creditCard = {
      ...values,
      name: '3DS hq',
      number: values.number.replace(/\s/g, ''),
      dueDate: values.dueDate.replace(/\D/g, ''),
    };

    return Paybox.api.createSubscriber(master, {
      uuid,
      id3D,
      amount,
      reference: customer.id,
      creditCard: helpers.object.filterByKeys(creditCard, ['number', 'dueDate', 'cvv']),
    })
      .catch((err) => {
        throw Object.assign(err, { handled: true });
      })
      .then((res) => Paybox.api.setCreditCard(master, {
        id: uuid,
        token: res.PORTEUR,
        accountId: customer.id,
        number: Paybox.functions.anonymizeNumber(creditCard.number),
        ...helpers.object.filterByKeys(creditCard, ['name', 'dueDate', 'type', 'brand', 'country']),
      }))
      .catch((err) => {
        if (!err.handled) {
          throw Object.assign(err, {
            handled: true,
            message: 'Impossible d’enregistrer la carte.',
          });
        }
        throw err;
      })
      .then((r) => {
        setSelected(r);
        history.replace('recurring-payment?status=successful');
      })
      .catch((e) => {
        setSubmitting(false);
        setError(e.message);
      });
  };

  if (!customer) return null;

  /* */
  return (
    <Screen
      error={error}
      loading={loading}>
      <Box mb={2}>
        <Typography
          variant="h5"
          component="h2">
          {`${customer.firstname} ${customer.lastname}`}
        </Typography>
        <Typography
          color="primary"
          variant="subtitle1"
          component="h3">
          {match && (
            <span>{match.reference}</span>
          )}
          {` / ${customer.email}`}
        </Typography>
      </Box>
      {selected ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center">
          <CheckCircleOutlineIcon fontSize="large" color="primary" />
          <br />
          <Typography gutterBottom color="primary">
            {'Carte bancaire renseignée avec succès.'}
          </Typography>
          <CreditCardPreview
            brand={selected.brand}
            number={selected.number}
            dueDate={selected.dueDate} />
        </Box>
      ) : (
        <RecurringPayment
          amount={amount}
          imprint={imprint}
          session3DS={session3DS}
          payWithNewCard={payWithNewCard}
          payWithCardImprint={payWithCardImprint} />
      )}
    </Screen>
  );
};

const mapStateToProps = ({ app }) => ({ ...app });

export default connect(mapStateToProps)(ConfirmRecurPayment);
